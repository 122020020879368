import BulkController from "./bulk_controller";
import FlashController from "./flash_controller"; // Importar o controlador de flash

export default class extends BulkController {
  static targets = ["checkbox", "selectAll"]

  connect() {
    super.connect();
    this.resetCheckboxes();  // Resetar checkboxes ao conectar
    this.updateBulkUpdateButton();
  }

  change(event) {
    super.change(event);
    this.updateBulkUpdateButton();
  }

  toggleSelectAll(event) {
    super.toggleSelectAll(event);
    this.updateBulkUpdateButton();
  }

  updateBulkUpdateButton() {
    const bulkUpdateButton = document.getElementById("bulk-update-button");
    const selectedIds = this.selectedIds;

    if (bulkUpdateButton) {
      bulkUpdateButton.dataset.selectedIds = JSON.stringify(selectedIds);

      if (selectedIds.length === 0) {
        bulkUpdateButton.classList.add("hidden");
      } else {
        bulkUpdateButton.classList.remove("hidden");
      }
    } else {
      console.error("Bulk update button not found");
    }
  }

  get selectedIds() {
    return this.checkboxTargets.filter(target => target.checked).map(target => target.value);
  }

  updateStatus(event) {
    event.preventDefault();
    const bulkUpdateButton = document.getElementById("bulk-update-button");
    if (!bulkUpdateButton) {
      console.log("Bulk update button not found");
      return;
    }

    const url = bulkUpdateButton.getAttribute("data-url");
    const selectedIds = JSON.parse(bulkUpdateButton.dataset.selectedIds || '[]');

    console.log("Selected IDs:", selectedIds);

    if (selectedIds.length === 0) {
      FlashController.setFlashMessage('Nenhum pedido selecionado.');
      return;
    }

    if (confirm('Você tem certeza que deseja mudar todos os pedidos selecionados para Pedido Entregue?')) {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        },
        body: JSON.stringify({ order_ids: selectedIds })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            FlashController.setFlashMessage("Todos os pedidos foram atualizados com sucesso!", "success");
            FlashController.reloadPage();
          } else {
            FlashController.setFlashMessage(data.message, "warning");
            FlashController.reloadPage();
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar pedidos:", error);
          FlashController.setFlashMessage("Ocorreu um erro ao atualizar os pedidos.", "warning");
          FlashController.reloadPage();
        });
    }
  }

  resetCheckboxes() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
    this.selectAllTarget.checked = false; // Reseta o checkbox de "Selecionar Todos"
    this.updateBulkUpdateButton(); // Atualiza o estado do botão
  }
}
