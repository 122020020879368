import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "info"];
  static values = { url: String };

  fetchSubscription() {
    const clientId = this.selectTarget.value;
    if (clientId) {
      fetch(`${this.urlValue}/${clientId}/subscription_info`, {
        headers: { Accept: "text/vnd.turbo-stream.html" },
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
        .catch(error => console.error("Erro ao carregar os dados do cliente:", error));
    }
  }
}
