import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tabs-mobile"
export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.selectTarget.addEventListener("change", this.changeTab.bind(this));
  }

  changeTab(event) {
    const path = event.target.value;
    const turboFrameTarget =
      this.selectTarget.getAttribute("data-turbo-target");
    const frame = document.querySelector(`turbo-frame#${turboFrameTarget}`);

    if (frame) {
      frame.src = path;
    }
  }
}
