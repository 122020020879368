import BulkController from "./bulk_controller";
import FlashController from "./flash_controller"; // Importando o controlador de flash

export default class extends BulkController {
  static targets = ["checkbox", "selectAll"]

  connect() {
    super.connect();
    this.updateBulkUpdateButtons();
  }

  change(event) {
    super.change(event);
    this.updateBulkUpdateButtons();
  }

  toggleSelectAll(event) {
    super.toggleSelectAll(event);
    this.updateBulkUpdateButtons();
  }

  updateBulkUpdateButtons() {
    const bulkUpdateButton = document.getElementById("bulk-update-button-boardgames");
    const bulkDesactivateButton = document.getElementById("bulk-update-button-boardgames-desactivate");
    const selectedIds = this.selectedIds;

    if (bulkUpdateButton) {
      bulkUpdateButton.dataset.selectedIds = JSON.stringify(selectedIds);
    } else {
      console.error("Bulk update button not found");
    }

    if (bulkDesactivateButton) {
      bulkDesactivateButton.dataset.selectedIds = JSON.stringify(selectedIds);
    } else {
      console.error("Bulk deactivate button not found");
    }
  }

  get selectedIds() {
    return this.checkboxTargets.filter(target => target.checked).map(target => target.value);
  }

  async desactivate(event) {
    event.preventDefault();
    const bulkDesactivateButton = document.getElementById("bulk-update-button-boardgames-desactivate");
    if (!bulkDesactivateButton) {
      alert("Bulk deactivate button not found");
      return;
    }

    const url = bulkDesactivateButton.dataset.url;
    const selectedIds = JSON.parse(bulkDesactivateButton.dataset.selectedIds || '[]');

    console.log("Selected IDs:", selectedIds);

    if (selectedIds.length === 0) {
      FlashController.setFlashMessage('Nenhum boardgame selecionado.', "warning");
      FlashController.reloadPage();
      return;
    }

    if (confirm('Você tem certeza que deseja desativar todos os boardgames selecionados?')) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
          },
          body: JSON.stringify({ boardgame_ids: selectedIds })
        });

        const data = await response.json();
        if (data.success) {
          FlashController.setFlashMessage("Todos os boardgames foram desativados com sucesso!", "success");
        
        } else {
          FlashController.setFlashMessage(data.message, "warning");

        }
      } catch (error) {
        console.error("Erro ao desativar boardgames:", error);
        FlashController.setFlashMessage("Ocorreu um erro ao desativar os boardgames.", "warning");
      } finally {
        FlashController.reloadPage();
      }
    }
  }

  async updateStatus(event) {
    event.preventDefault();
    const bulkUpdateButton = document.getElementById("bulk-update-button-boardgames");
    if (!bulkUpdateButton) {
      alert("Bulk update button not found");
      return;
    }

    const url = bulkUpdateButton.dataset.url;
    const selectedIds = JSON.parse(bulkUpdateButton.dataset.selectedIds || '[]');

    console.log("Selected IDs:", selectedIds);

    if (selectedIds.length === 0) {
      FlashController.setFlashMessage('Nenhum boardgame selecionado.', "warning");
      FlashController.reloadPage();
      return;
    }

    if (confirm('Você tem certeza que deseja mudar todos os boardgames selecionados para Disponível?')) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
          },
          body: JSON.stringify({ boardgame_ids: selectedIds })
        });

        const data = await response.json();
        if (data.success) {
          FlashController.setFlashMessage("Todos os boardgames foram atualizados com sucesso!", "success");
          FlashController.reloadPage();
        } else {
          FlashController.setFlashMessage(data.message, "warning");
          FlashController.reloadPage();
        }
      } catch (error) {
        console.error("Erro ao atualizar boardgames:", error);
        FlashController.setFlashMessage("Ocorreu um erro ao atualizar os boardgames.", "warning");
      } finally {
        FlashController.reloadPage();
      }
    }
  }

  reloadPage() {
    location.reload();
  }
}
