import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clear-form-fields"
export default class extends Controller {

  static targets = [ "checkboxField", "textField", "dateField" ]
  connect() {
  }

  clearFields() {
    this.textFieldTargets.forEach((textField) => {
      textField.value = ""
    })

    this.checkboxFieldTargets.forEach((checkboxField) => {
      checkboxField.checked = false
    })
    
    this.dateFieldTargets.forEach((dateField) => {
      dateField.value = "";
    })
  }
}
