import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mask-input"
export default class extends Controller {
  static targets = ["input"];
  static values = { maskType: String }; // Define o tipo de máscara

  connect() {

    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("input", this.applyMask.bind(this));
      this.inputTarget.value = this.applyMaskValue(
        this.inputTarget.value.replace(/\D/g, "")
      );
    }
    this.element.addEventListener("submit", this.cleanInput.bind(this));
  }

  applyMask(event) {
    let value = event.target.value.replace(/\D/g, "");
    event.target.value = this.applyMaskValue(value);
  }

  cleanInput(event) {
    this.inputTarget.value = this.cleanMask(this.inputTarget.value);
  }

  applyMaskValue(value) {
    switch (this.maskTypeValue) {
      case "phone":
        return this.applyPhoneMask(value);
      case "date":
        return this.applyDateMask(value);
      default:
        return value;
    }
  }

  applyPhoneMask(value) {
    if (value.length > 11) value = value.slice(0, 11);
    if (value.length > 2) value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    if (value.length > 10) value = `${value.slice(0, 10)}-${value.slice(10)}`;
    return value;
  }

  applyDateMask(value) {
    if (value.length > 8) value = value.slice(0, 8);
    if (value.length > 2) value = `${value.slice(0, 2)}/${value.slice(2)}`;
    if (value.length > 5) value = `${value.slice(0, 5)}/${value.slice(5)}`;
    return value;
  }

  cleanMask(value) {
    return value.replace(/\D/g, "");
  }
}
