import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog"];

  open(event) {
    event.preventDefault();
    this.dialogTarget.showModal();
  }

  close(event) {
    event.preventDefault();
    this.dialogTarget.close();
  }

  backdropClose(event) {
    if (event.target === this.dialogTarget) {
      this.close(event);
    }
  }
}
