import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="price-toggle"
export default class extends Controller {
  static targets = [
    "priceOptions",
    "select",
    "price",
    "days",
    "manualFields",
    "addManualButton",
    "usePredefinedButton",
    "manualEntry"
  ];

  connect() {
    this.updateFieldsState(this.manualEntryTarget.value === "true" ? "manual" : "predefined");
  }

  addManual() {
    this.updateFieldsState("manual");
  }

  usePredefined() {
    this.updateFieldsState("predefined");
  }

  updateFieldsState(state) {
    const isManual = state === "manual";

    // Update manual entry field
    this.manualEntryTarget.value = isManual ? "true" : "false";

    // Toggle field states
    this.selectTarget.disabled = isManual;
    this.priceTarget.disabled = !isManual;
    this.daysTarget.disabled = !isManual;

    // Update appearance
    this.priceOptionsTarget.classList.toggle("opacity-50", isManual);
    this.priceOptionsTarget.classList.toggle("translate-y-2", isManual);
    this.priceOptionsTarget.classList.toggle("opacity-100", !isManual);
    this.priceOptionsTarget.classList.toggle("translate-y-0", !isManual);

    this.manualFieldsTargets.forEach((field) => {
      field.classList.toggle("opacity-50", !isManual);
      field.classList.toggle("opacity-100", isManual);
      field.classList.toggle("translate-y-2", !isManual);
      field.classList.toggle("translate-y-0", isManual);
    });

    // Set days and price fields values based on the selected state
    if (!isManual) {
      this.priceTarget.value = this.priceTarget.dataset.defaultValue || 0;
      this.daysTarget.value = this.daysTarget.dataset.defaultValue || "";
    } else {
      this.priceTarget.value = this.priceTarget.value || 0;
      this.daysTarget.value = this.daysTarget.value || 0;
    }


    // Toggle button visibility
    this.addManualButtonTarget.classList.toggle("hidden", isManual);
    this.usePredefinedButtonTarget.classList.toggle("hidden", !isManual);
  }
}
