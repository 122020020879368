import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "password",
    "passwordConfirmation",
    "submitButton",
    "errorMessage",
  ];

  connect() {
    this.resetFormState();
  }

  validatePasswords() {
    if (this.arePasswordsFilled()) {
      if (this.doPasswordsMatch()) {
        this.enableSubmitButton();
        this.hideErrorMessage();
      } else {
        this.disableSubmitButton();
        this.showErrorMessage("As senhas não correspondem.");
      }
    } else {
      this.disableSubmitButton();
      this.hideErrorMessage();
    }
  }

  togglePasswordVisibility(event) {
    const button = event.currentTarget;
    const inputTargetName = button.dataset.inputTarget;
    const input = this[inputTargetName];
    const eyeOpenIcon = button.querySelector(".eye-open");
    const eyeClosedIcon = button.querySelector(".eye-closed");

    if (input.type === "password") {
      input.type = "text";
      eyeOpenIcon.classList.add("hidden");
      eyeClosedIcon.classList.remove("hidden");
    } else {
      input.type = "password";
      eyeOpenIcon.classList.remove("hidden");
      eyeClosedIcon.classList.add("hidden");
    }
  }

  // Métodos auxiliares

  resetFormState() {
    this.disableSubmitButton();
    this.hideErrorMessage();
    this.setInputsType("password");
  }

  arePasswordsFilled() {
    return this.passwordTarget.value && this.passwordConfirmationTarget.value;
  }

  doPasswordsMatch() {
    return this.passwordTarget.value === this.passwordConfirmationTarget.value;
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove(
      "opacity-50",
      "cursor-not-allowed",
    );
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add("opacity-50", "cursor-not-allowed");
  }

  showErrorMessage(message) {
    this.errorMessageTarget.textContent = message;
    this.errorMessageTarget.classList.remove("hidden");
  }

  hideErrorMessage() {
    this.errorMessageTarget.textContent = "";
    this.errorMessageTarget.classList.add("hidden");
  }

  setInputsType(type) {
    this.passwordTarget.type = type;
    this.passwordConfirmationTarget.type = type;
  }
}
