import BulkController from "./bulk_controller";
import FlashController from "./flash_controller";

export default class extends BulkController {
  static targets = ["selectAll", "checkbox"];

  connect() {
    this.setupTurboLoadListener();
    this.checkSelectAll();
    this.updateBulkButtons();
  }

  disconnect() {
    this.teardownTurboLoadListener();
  }

  setupTurboLoadListener() {
    document.removeEventListener("turbo:load", this.checkSelectAll.bind(this));
    document.addEventListener("turbo:load", this.checkSelectAll.bind(this));
  }

  teardownTurboLoadListener() {
    document.removeEventListener("turbo:load", this.checkSelectAll.bind(this));
  }

  checkSelectAll() {
    if (this.hasSelectAllTarget) {
      this.resetCheckboxes();
    } else {
      this.devLog("O elemento 'selectAll' não foi encontrado, esperando...");
    }
  }

  change(event) {
    super.change(event);
    this.updateBulkButtons();
  }

  toggleSelectAll(event) {
    super.toggleSelectAll(event);
    this.updateBulkButtons();
  }

  get selectedIds() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
  }

  get selectedBoardgames() {
    // Buscar todos os checkboxes diretamente do DOM que estão marcados
    const checkboxes = document.querySelectorAll('[data-rents-bulk-target="checkbox"]');
    const boardgames_checked = Array.from(checkboxes).filter((checkbox) => checkbox.checked);

    // Retornar um array de objetos com o nome do jogo
    const boardgames = boardgames_checked.map((checkbox) => ({
      name: checkbox.getAttribute("data-boardgame-name"),
    }));

    return boardgames;
  }


  updateBulkButtons() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const bulkReturnButton = this.getButton('return', isMobile);
    const bulkRenewButton = this.getButton('renew', isMobile);

    const selectedIds = this.selectedIds;

    if (bulkReturnButton && bulkRenewButton) {
      bulkReturnButton.dataset.selectedIds = JSON.stringify(selectedIds);
      bulkRenewButton.dataset.selectedIds = JSON.stringify(selectedIds);

      this.toggleVisibility(bulkReturnButton, selectedIds.length > 0);
      this.toggleVisibility(bulkRenewButton, selectedIds.length > 0);
    } else {
      console.error("Bulk action button not found");
    }
  }

  resetCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
    this.selectAllTarget.checked = false;
    this.updateBulkButtons();
  }

  getButton(actionType, isMobile) {
    const buttonId = `bulk-${actionType}-button-rent-${isMobile ? 'mobile' : 'desktop'}`;
    return document.getElementById(buttonId);
  }

  toggleVisibility(element, isVisible) {
    element.classList.toggle("hidden", !isVisible);
  }

  sendBulkUpdateRequest(url, selectedIds) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    if (!csrfToken) {
      console.error("CSRF token não encontrado.");
      return;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ rent_ids: selectedIds }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const messageType = data.success ? "success" : "warning";
        FlashController.setFlashMessage(
          data.success ? "Todos os alugueis foram atualizados com sucesso!" : data.message,
          messageType
        );
        FlashController.reloadPage();
      })
      .catch((error) => {
        console.error("Erro ao atualizar alugueis:", error);
        FlashController.setFlashMessage("Ocorreu um erro ao atualizar os alugueis.", "warning");
        FlashController.reloadPage();
      });
  }

  handleBulkAction(event) {
    event.preventDefault();

    const actionType = event.currentTarget.dataset.actionType;
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const button = this.getButton(actionType, isMobile);

    if (!button) {
      this.showErrorModal(this.getAlertMessage(actionType));
      return;
    }

    const url = button.getAttribute("data-url");
    const selectedIds = JSON.parse(button.dataset.selectedIds || '[]');

    if (selectedIds.length === 0) {
      FlashController.setFlashMessage('Nenhum aluguel selecionado.');
      return;
    }

    const confirmationMessage = this.getConfirmationMessage(actionType);
    const selectedBoardgames = this.selectedBoardgames;

    this.showConfirmationModal(
      "Confirmar Ação",
      confirmationMessage,
      selectedBoardgames,
      () => this.performAction(url, selectedIds, actionType)
    );
  }

  // Function to open the modal with title, body, boardgame list, and confirmation action
  showConfirmationModal(title, body, selectedBoardgames, onConfirm) {
    const modalElement = document.querySelector("#modal");

    if (!modalElement) {
      console.error("Modal element not found.");
      return;
    }

    const modalTitle = modalElement.querySelector("#modal-title");
    const modalBody = modalElement.querySelector("#modal-body");
    const confirmButton = modalElement.querySelector("#modal-confirm-button");
    const boardgamesList = modalElement.querySelector("#selected-boardgames-list");

    // Set title and body
    modalTitle.textContent = title;
    modalBody.textContent = body;

    // Clear existing boardgame list and add the selected boardgames
    boardgamesList.innerHTML = "";
    selectedBoardgames.forEach(boardgame => {
      const li = document.createElement("li");
      li.textContent = `${boardgame.name}`;  // Exibe o nome 
      boardgamesList.appendChild(li);
    });

    // Replace the confirm button's event listener
    confirmButton.replaceWith(confirmButton.cloneNode(true));  // Clear existing listeners
    modalElement.querySelector("#modal-confirm-button").addEventListener("click", (e) => {
      e.preventDefault();
      onConfirm();  // Execute the action on confirmation
      this.closeModal(modalElement);
    });

    // Show the modal
    modalElement.showModal();
  }

  // Function to close the modal
  closeModal(modalElement) {
    modalElement.close();
  }

  // Function to show an error modal
  showErrorModal(message) {
    this.showConfirmationModal("Erro", message, [], () => {
    });
  }

  performAction(url, selectedIds, actionType) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ rent_ids: selectedIds }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(data => {
            throw new Error(data.message || `Erro ao ${this.getErrorMessage(actionType)} os alugueis.`);
          });
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          FlashController.setFlashMessage(`${this.getSuccessMessage(actionType)} com sucesso!`, "success");
        } else {
          FlashController.setFlashMessage(data.message, "warning");
        }
        FlashController.reloadPage();
      })
      .catch(error => {
        // Não exibe erro técnico no console, apenas uma mensagem amigável
        FlashController.setFlashMessage(`Ocorreu um erro ao ${this.getErrorMessage(actionType)} os alugueis.`, "warning");
        FlashController.reloadPage();
      });
  }

  getConfirmationMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'Você tem certeza que deseja marcar todos os alugueis selecionados como Devolvido?';
      case 'renew':
        return 'Você tem certeza que deseja renovar todos os alugueis selecionados?';
      default:
        return 'Você tem certeza que deseja realizar esta ação?';
    }
  }

  getAlertMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'Bulk return button not found';
      case 'renew':
        return 'Bulk renew button not found';
      default:
        return 'Invalid action type';
    }
  }

  getSuccessMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'Status atualizado';
      case 'renew':
        return 'Aluguéis renovados';
      default:
        return 'Ação realizada';
    }
  }

  getErrorMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'atualizar';
      case 'renew':
        return 'renovar';
      default:
        return 'realizar a ação';
    }
  }

  devLog(...messages) {
    if (process.env.NODE_ENV === "development") {
      console.log(...messages);
    }
  }
}
