import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source"];

  connect() {
  }

  handleSelectChange() {
    let formData = new FormData();
    formData.append(`${this.data.get("sourceParamsPart")}`, `${this.sourceTarget.value}`);
    this.sendPutRequest(this.data.get("sourceRoutePart"), formData);
  }

  sendPutRequest(url, data) {
    fetch(url, {
      method: "PUT",
      credentials: "same-origin",
      body: data,
    });
  }
}
