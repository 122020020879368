import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["display", "form"];

  edit() {
    if (this.hasDisplayTarget && this.hasFormTarget) {
      this.displayTarget.classList.add("hidden");
      this.formTarget.classList.remove("hidden");
    }
  }

  cancel() {
    if (this.hasDisplayTarget && this.hasFormTarget) {
      this.formTarget.classList.add("hidden");
      this.displayTarget.classList.remove("hidden");
    }
  }
}

