import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";
// Connects to data-controller="search"
export default class extends Controller {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 400);
  }
  submit() {
    let url = new URL(this.element.baseURI);
    let params = new URLSearchParams(url.search);
    let tab = params.get('tab');

    this.addHiddenParam('tab', tab);
    this.showLoadingSpinner(); // Show loading spinner, you need to add the <%= loading_spinner %> on the page

    this.element.requestSubmit();
  }

  addHiddenParam(name, value) {
    let hiddenField = this.element.querySelector(`input[name="${name}"]`);

    if (hiddenField) {
      hiddenField.value = value;
    } else {
      hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', name);
      hiddenField.setAttribute('value', value);
      this.element.appendChild(hiddenField);
    }
  }

  showLoadingSpinner() {
    ['loading-spinner', 'loading-spinner-mobile'].forEach(id => {
      const spinner = document.getElementById(id);
      if (spinner) {
        spinner.classList.remove('hidden');
      }
    });
  }
}
