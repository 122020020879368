import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status", "submitButton"];

  // This method is called when the status select changes
  checkStatusChange() {
    const status = this.statusTarget.value;

    // Check if the status is "Pedido entregue" or "Pedido cancelado"
    if (status === "Pedido entregue" || status === "Pedido cancelado") {
      // Adds the turbo_confirm attribute to the submit button
      this.submitButtonTarget.setAttribute(
        "data-turbo-confirm",
        "Marcar um pedido como entregue ou cancelado é uma ação irreversivel. Deseja prosseguir?"
      );
    } else {
      // Removes the turbo_confirm attribute from the submit button
      this.submitButtonTarget.removeAttribute("data-turbo-confirm");
    }
  }
}
