import { Controller } from "@hotwired/stimulus";
import L from "leaflet";

export default class extends Controller {
  static values = {
    storesUrl: String,
    initialCoordinates: Array,
    zoom: Number,
  };

  connect() {
    // Coordenadas iniciais vindas dos valores definidos no HTML
    const initialLatLng = this.initialCoordinatesValue || [-23.5505, -46.6333]; // São Paulo como default
    const zoomLevel = this.zoomValue || 13; // Zoom default

    // Cria o mapa na div
    const map = L.map(this.element).setView(initialLatLng, zoomLevel);

    // Adiciona a camada de tiles do OpenStreetMap
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Carrega os dados das lojas a partir da URL fornecida
    fetch(this.storesUrlValue)
      .then((response) => response.json())
      .then((stores) => {
        stores.forEach((store) => {
          // Adiciona um marcador para cada loja
          const marker = L.marker([store.latitude, store.longitude]).addTo(map);
          marker.bindPopup(`<b>${store.name}</b>`); // Mostra o nome da loja
        });
      })
      .catch((error) => console.error("Erro ao carregar as lojas:", error));
  }
}
