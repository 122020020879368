import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // O método stopPropagation é utilizado para interromper a propagação do evento atual.
  // para os elementos ancestrais. Isso é necessário porque, anteriormente, ao clicar
  // no modal, ele fechava inesperadamente. Esse comportamento era causado pelo evento
  // de clique no backdrop, que fechava o modal quando acionado. stopPropagation para evitar que o evento de clique
  // no modal se propague para o backdrop, prevenindo assim o fechamento indesejado do modal.

  stopPropagation(event) {
    event.stopPropagation();
  }
}
