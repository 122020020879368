import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable-fields"
export default class extends Controller {
  static targets = ["field", "toggleButton", "container", "initiallyDisabled"];

  connect() {
    this.disableInitially();
  }

  // This method is called when the checkbox is clicked
  toggleFields() {
    const isChecked = this.toggleButtonTarget.checked;

    this.fieldTargets.forEach((field) => {
      field.disabled = isChecked;
    });

    this.initiallyDisabledTargets.forEach((field) => {
      field.disabled = !isChecked;
    });

    this.applyOpacity();
  }

  disableInitially() {
    this.initiallyDisabledTargets.forEach((field) => {
      field.disabled = true;
    });
  }

  applyOpacity() {
    const isChecked = this.toggleButtonTarget.checked;
    this.containerTarget.classList.toggle("opacity-50", isChecked);
  }
}