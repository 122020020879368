import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flashContainer"]

  connect() {
    this.checkStoredFlashMessage();
  }

  checkStoredFlashMessage() {
    const storedMessage = localStorage.getItem('flashMessage');
    if (storedMessage) {
      this.flashContainerTarget.innerHTML = storedMessage;
      this.flashContainerTarget.classList.remove("hidden");
      setTimeout(() => {
        this.flashContainerTarget.classList.add("hidden");
      }, 5000); // Oculta a mensagem após 5 segundos
      localStorage.removeItem('flashMessage');
    }
  }

  static setFlashMessage(message, type = 'info') {
    const flashHtml = `
      <div class="notice notice-${type} dark:bg-${type}-700 dark:text-${type}-300" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-primary-700 dark:text-primary-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
        </svg>
        <p>${message}</p>
      </div>`;
    localStorage.setItem('flashMessage', flashHtml);
  }

  static reloadPage() {
    location.reload();
  }
}
