import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    url: String,
    valueField: {type: String, default: "value"},
    labelField: {type: String, default: "label"},
    submitOnChange: false
  }

  connect() {
    if (this.hasUrlValue) {
      this.initializeTomSelect()
    } else {
      this.select = new TomSelect(this.element)
    }
  }

  disconnect() {
    this.select.destroy()
  }

  initializeTomSelect() {
    const url = `${this.urlValue}.json`

    const fetchData = (query, callback) => {
      fetch(`${url}?query=${query}`)
        .then(response => response.json())
        .then(data => callback(data))
        .catch(() => callback())
    }

    const renderOption = (data, escape) => {
      return `
        <div class="flex">
          <img src="${data.image}" class="w-8 h-8 rounded-full mr-4" />
          <span class="block">${escape(data.name)}</span>
        </div>
      `;
    };

    const options = {
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.labelFieldValue,
      placeholder: "Selecione um jogo base",
      preload: true,
      load: fetchData,
      render: {
        option: renderOption
      }
    }

    this.select = new TomSelect(this.element, options)
  }

  submitOnChange(value) {
    if (value) {
      this.element.form.requestSubmit()
      this.select.clear(true) // resets silently
    }
  }
}
