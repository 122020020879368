import {Modal} from "tailwindcss-stimulus-components";

// Connects to data-controller="form-modal"
export default class extends Modal {
  static targets = ["dialog"];

  static values = {
    open: Boolean,
  };

  connect() {
    super.connect();
  }

  close() {
    this.element.parentElement.removeAttribute("src");
    super.close();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }
}
