import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="coupon-visibility"
export default class extends Controller {
  static targets = [
    "typeSelect",
    "nominalField",
    "percentageField",
    "nominalInput",
    "percentageInput",
  ];

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const selectedType = this.typeSelectTarget.value;
    //Hide both Fields to make sure we have only one appearing when called the function
    this.hideFields(this.nominalFieldTarget, this.percentageFieldTarget);

    //Shows only the necessary field and make sure the others are empty

    switch (selectedType) {
      case "Valor fixo":
        this.showField(this.nominalFieldTarget);
        this.clearInputFields(this.percentageInputTarget);
        break;
      case "Porcentagem":
        this.showField(this.percentageFieldTarget);
        this.clearInputFields(this.nominalInputTarget);
        break;
      case "Frete grátis":
        this.clearInputFields(
          this.nominalInputTarget,
          this.percentageFieldTarget
        );
        break;
    }
  }

  hideFields(...fields) {
    fields.forEach((field) => field.classList.add("hidden"));
  }

  showField(field) {
    field.classList.remove("hidden");
  }

  clearInputFields(...fields) {
    fields.forEach((field) => (field.value = null));
  }
}
